import React from "react";
import { Helmet } from "react-helmet";
import PrivacyPolicy from "../../components/Termly/PrivacyPolicy";
import ComplianceNav from "../../components/Nav/ComplianceNav";

const PrivacyPolicyPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy | Gojira Labs</title>
      </Helmet>
      <div className={`bg-grey text-white px-4 md:px-8 pt-32 pb-24`}>
        <div
          className={`w-full bg-white md:px-8 py-4 md:py-8 max-w-[850px] mx-auto`}
        >
          <ComplianceNav />
          <PrivacyPolicy />
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyPage;
