import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("id", "termly-jssdk");
    script.setAttribute("src", "https://app.termly.io/embed-policy.min.js");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      name="termly-embed"
      data-id="66ed7cb0-e893-4c3a-b86b-5cf35104a35e"
      data-type="iframe"
    ></div>
  );
};

export default PrivacyPolicy;
